<template>
  <v-snackbar
    v-model="showNotification"
    class="success"
    close-on-content-click
    :color="infoType === 'success' ? 'success-message' : 'error'"
    location="bottom"
    rounded="lg"
    :timeout="10000"
    variant="flat"
  >
    <div class="d-flex align-center justify-center">
      <Icon
        class="icon mr-2"
        :class="infoType === 'success' ? 'text-primary' : 'text-input-bg'"
        :name="icon"
        size="18"
      />
      <span
        class="text__headline-h5 pl-2"
        :class="infoType === 'success' ? 'text-primary' : 'text-input-bg'"
      >
        {{ info }}
      </span>
    </div>
  </v-snackbar>
</template>

<script setup lang="ts">
import { useNotification } from '~/composables/useNotification';

const { iconName, info, infoType, showNotification } = useNotification();

const icon = computed(() => {
  if (iconName.value) return iconName.value;
  return infoType.value === 'success' ? 'material-symbols:check-circle-rounded' : 'material-symbols:error-circle-rounded';
});
</script>

<style scoped lang="scss">
.icon {
  min-width: 25px;
}
</style>
